<template>
  <div class="view-container">
    <div class="card staff-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Работа с сотрудниками</div>
        <div class="tools">
          <router-link to="/group/edit" class="btn btn-accent"
            >Создать группу</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <excel-load-row class="my-10" />
        <group-quota-row
          v-if="getRight('groupQuota')"
          :groupSelected="groupSelected"
        />
        <group-table @groupSelected="selectGroup" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import ExcelLoadRow from "@/feature/users/ExcelLoadRow.vue";
import GroupQuotaRow from "@/feature/group-control/GroupQuotaRow.vue";
import GroupTable from "@/feature/group-control/tables/GroupTable.vue";
import useCheckRights from "@/mixins/useCheckRights";
export default {
  components: { GroupTable, ExcelLoadRow, GroupQuotaRow },
  name: "group-page",
  setup() {
    const { getRight } = useCheckRights(),
      groupSelected = ref(null);

    const selectGroup = (value) => {
        groupSelected.value = value;
    };

    return {
      getRight,
      groupSelected,
      selectGroup,
    };
  },
};
</script>

<style>
</style>