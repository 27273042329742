<template>
    <div class="group-quota">
        <div class="flex">
            <div
                    v-if="!showQuots"
                    class="btn btn-accent quota-btn m-auto show"
                    @click="showQuots = true"
            >
                Показать квоты <i class="fal fa-angle-down"></i>
            </div>
            <div
                    v-else
                    class="btn btn-accent quota-btn m-auto hide"
                    @click="showQuots = false"
            >
                Скрыть квоты <i class="fal fa-angle-up"></i>
            </div>
        </div>
        <transition name="slide-down">
            <div v-if="showQuots" class="group-quota__instructions">
                <div class="instruction-block">
                    <div class="instruction-block__label">
                        1. Посмотрите, сколько у вас есть доступных квот.
                    </div>
                    <quota-tables
                            v-if="companyQuota"
                            :courseOptions="courseOptions"
                            :quota="companyQuota"
                            :testOptions="testOptions"
                            :trajectoriesOptions="trajectoriesOptions"
                    />
                </div>
                <div class="flex group-quota__params quota-params instruction-block flex-column">
                    <div class="instruction-block__label">
                        2. Выберите тип отчета, курс или траекторию и укажите их количество.
                    </div>
                    <div class="flex align-item-center quota-params__line flex-column">
                        <custom-select-2
                                name="testType"
                                v-model="quota.reportType"
                                :options="[{ id: '0', text: 'Выберите тип отчета' },...testOptions,]"
                                class="flex-1"
                                :inputWidth="98"
                        />
                        <div class="w-full quota-params__btn">
                            <custom-input
                                    name="testsCount"
                                    type="number"
                                    v-model="quota.testCount"
                                    class="flex-1"
                                    label="Количество тестов"
                                    :minValue="0"
                            />
                        </div>
                        <div class="flex justify-content-end w-full quota-params__btn">
                            <button @click="giveTests" class="btn btn-accent">
                                Выдать тесты
                            </button>
                            <button @click="takeTests" class="btn btn-w take-btn">
                                Забрать тесты
                            </button>
                        </div>
                    </div>
                    <div class="flex align-item-center quota-params__line flex-column">
                        <custom-select-2
                                v-model="quota.course"
                                name="courseCode"
                                :options="['Выберите курс', ...courseOptions]"
                                class="flex-1"
                                :inputWidth="98"
                        />
                        <div class="w-full quota-params__btn">
                            <custom-input
                                    v-model="quota.courseCount"
                                    name="coursesCount"
                                    type="number"
                                    label="Количество курсов"
                                    class="flex-1"
                                    :minValue="0"
                            />
                        </div>
                        <div class="flex justify-content-end w-full quota-params__btn">
                            <button @click="giveCourses" class="btn btn-accent">
                                Выдать курсы
                            </button>
                            <button @click="takeCourses" class="btn btn-w take-btn">
                                Забрать курсы
                            </button>
                        </div>
                    </div>

                    <div class="flex align-item-center quota-params__line flex-column">
                        <div class="w-full quota-params__btn">
                            <custom-input
                                    v-model="quota.trajectoriesCount"
                                    name="trajectoriesCount"
                                    type="number"
                                    label="Количество траекторий"
                                    class="flex-1"
                                    :minValue="0"
                            />
                        </div>
                        <div class="flex justify-content-end w-full quota-params__btn">
                            <button @click="giveTrajectories" class="btn btn-accent">
                                Выдать траектории
                            </button>
                            <button @click="takeTrajectories" class="btn btn-w take-btn">
                                Забрать траектории
                            </button>
                        </div>
                    </div>
                </div>
                <div class="instruction-block">
                    <div class="instruction-block__label">
                        3. Отметьте галочкой нужную группу для выдачи квот ниже и нажмите
                        “Выдать тесты”, “Выдать курсы” или “Выдать траектории”.
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {reactive, ref, toRef} from "@vue/reactivity";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import {notify} from "@kyvg/vue3-notification";
import useQuotaCount from "../../mixins/useQuotaCount";
import QuotaTables from "./QuotaTables.vue";

export default {
    components: {CustomInput, CustomSelect2, QuotaTables},
    name: "group-quota-row",
    props: {
        groupSelected: {
            required: false,
        },
    },
    setup(props) {
        const store = useStore(),
            {getCourseListGroup, getTestList, getTrajectoriesList} = useQuotaCount();
        const quota = reactive({
                reportType: "Выберите тип отчета",
                course: "Выберите курс",
                testCount: 0,
                courseCount: 0,
                trajectoriesCount: 0,
            }),
            showQuots = ref(false),
            group = toRef(props, "groupSelected");
        const profile = computed(() => store.state.profile.profile),
            companyQuota = computed(() => store.state.company.quota),
            courseOptions = ref([]),
            testOptions = ref([]),
            trajectoriesOptions = ref([]);

        onBeforeMount(() => {
            if (profile.value && profile.value.company) {
                store
                    .dispatch("company/getCompanyQuota", profile.value.company.id)
                    .then(() => {
                        courseOptions.value = getCourseListGroup(companyQuota.value.course);
                        testOptions.value = getTestList(companyQuota.value.test);
                        trajectoriesOptions.value = getTrajectoriesList(companyQuota.value.trajectories);
                    });
            }
        });

        const giveTests = () => {
                if (quota.reportType == "Выберите тип отчета")
                    notify({type: "warning", title: "Выберите тип отчета"});
                else if (quota.testCount == 0 || quota.testCount < 0)
                    notify({type: "warning", title: "Введите количество тестов"});
                else
                    store.dispatch("company/giveGroupPassings", {
                        id: group.value,
                        reportType: quota.reportType,
                        count: quota.testCount,
                    });
            },
            giveCourses = () => {
                if (quota.course == "Выберите курс")
                    notify({type: "warning", title: "Выберите один из курсов"});
                else if (quota.courseCount == 0 || quota.testCount < 0)
                    notify({type: "warning", title: "Введите количество курсов"});
                else
                    store.dispatch("company/giveGroupCourses", {
                        id: group.value,
                        courseCode: quota.course,
                        count: quota.courseCount,
                    });
            },
            giveTrajectories = () => {
                if (quota.trajectoriesCount == 0 || quota.trajectoriesCount < 0)
                    notify({type: "warning", title: "Введите количество траекторий"});
                else
                    store.dispatch("company/giveGroupTrajectories", {
                        id: group.value,
                        count: quota.trajectoriesCount,
                    });
            },
            takeTests = () => {
                if (quota.reportType == "Выберите тип отчета")
                    notify({type: "warning", title: "Выберите тип отчета"});
                else if (quota.testCount == 0 || quota.testCount < 0)
                    notify({type: "warning", title: "Введите количество тестов"});
                else
                    store.dispatch("company/takeGroupPassings", {
                        id: group.value,
                        reportType: quota.reportType,
                        count: quota.testCount,
                    });
            },
            takeCourses = () => {
                if (quota.course == "Выберите курс")
                    notify({type: "warning", title: "Выберите один из курсов"});
                else if (quota.courseCount == 0 || quota.testCount < 0)
                    notify({type: "warning", title: "Введите количество курсов"});
                else
                    store.dispatch("company/takeGroupCourses", {
                        id: group.value,
                        courseCode: quota.course,
                        count: quota.courseCount,
                    });
            },
            takeTrajectories = () => {
                if (quota.trajectoriesCount == 0 || quota.trajectoriesCount < 0)
                    notify({type: "warning", title: "Введите количество траекторий"});
                else
                    store.dispatch("company/takeGroupTrajectories", {
                        id: group.value,
                        count: quota.trajectoriesCount,
                    });
            };

        watch(profile, () => {
            if (profile.value && profile.value.company)
                store
                    .dispatch("company/getCompanyQuota", profile.value.company.id)
                    .then(() => {
                        courseOptions.value = getCourseListGroup(companyQuota.value.course);
                        testOptions.value = getTestList(companyQuota.value.test);
                        trajectoriesOptions.value = getTrajectoriesList(companyQuota.value.trajectories);
                    });
        });

        return {
            quota,
            showQuots,
            companyQuota,
            giveTests,
            giveCourses,
            courseOptions,
            takeTests,
            takeCourses,
            testOptions,
            trajectoriesOptions,
            takeTrajectories,
            giveTrajectories
        };
    },
};
</script>

<style scoped lang="scss">
.group-quota {
  border-bottom: 1px solid var(--cream-color);
  padding-bottom: 20px;
  margin-bottom: 30px;

  .quota-btn {
    display: flex;
    position: relative;
    align-items: center;
    line-height: 1.738;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    width: 500px;
    justify-content: center;
    margin: auto;

    i {
      font-size: 22px;
      margin-left: 10px;
      margin-top: 4px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__instructions {
    margin-top: 15px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    padding: 20px 15px;
  }

  @media (max-width: 976px) {
    &__title {
      padding-left: 0;
    }
  }
}
</style>