<template>
    <div class="flex quota-tables align-item-center flex-column">
        <div class="table-sm-container w-full">
            <table class="table-sm w-full">
                <thead>
                <th>Тесты</th>
                <th style="width: 150px">Выдано</th>
                <th style="width: 150px">Доступно</th>
                </thead>
                <tbody v-if="testOptions.length && testQuotas">
                <tr v-for="test in testOptions" :key="test.id">
                    <td>{{ test.text }}</td>
                    <td>
                        {{ getTestCount(testQuotas.issued, test.id) }}
                    </td>
                    <td>
                        {{ getTestCount(testQuotas.free, test.id) }}
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td>Нет доступных тестов</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="table-sm-container w-full">
            <table class="table-sm w-full">
                <thead>
                <th>Курсы</th>
                <th style="width: 150px">Выдано</th>
                <th style="width: 150px">Доступно</th>
                </thead>
                <tbody v-if="courseOptions.length && courseQuotas">
                <tr v-for="course in courseOptions" :key="course.id">
                    <td>{{ course.text }}</td>
                    <td>
                        {{ getCourseCount(courseQuotas.issued, course.id) }}
                    </td>
                    <td>
                        {{ getCourseCount(courseQuotas.free, course.id) }}
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td>Нет доступных курсов</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="table-sm-container w-full">
            <table class="table-sm w-full">
                <thead>
                <th>Траектории</th>
                <th style="width: 150px">Выдано</th>
                <th style="width: 150px">Доступно</th>
                </thead>
                <tbody v-if="trajectoriesOptions.length && trajectoriesQuotas">
                <tr v-for="trajectories in trajectoriesOptions" :key="trajectories.id">
                    <td>{{ trajectories.text }}</td>
                    <td>
                        {{ getTrajectoriesCount(trajectoriesQuotas.issued, trajectories.id) }}
                    </td>
                    <td>
                        {{ getTrajectoriesCount(trajectoriesQuotas.free, trajectories.id) }}
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td>Нет доступных траекторий</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {computed, toRef} from "vue";
import useQuotaCount from "../../mixins/useQuotaCount";

export default {
    name: "quota-tables",
    props: {
        quota: {
            type: Object,
        },
        courseOptions: {
            type: Array,
        },
        testOptions: {
            type: Array,
        },
        trajectoriesOptions: {
            type: Array,
        },
    },
    setup(props) {
        const {getTestCount, getCourseCount, getTrajectoriesCount} = useQuotaCount();
        const quota = toRef(props, "quota");
        const testQuotas = computed(() =>
            quota.value.test_passings ? quota.value.test_passings : quota.value.test
        );
        const courseQuotas = computed(() =>
            quota.value && quota.value.course_passings
                ? quota.value.course_passings
                : quota.value.course
        );
        const trajectoriesQuotas = computed(() =>
                     quota.value.trajectories
        );

        return {
            getTestCount,
            getCourseCount,
            getTrajectoriesCount,
            testQuotas,
            courseQuotas,
            trajectoriesQuotas
        };
    },
};
</script>

<style lang="scss" scoped>
.quota-tables {
  .table-sm-container {
    margin-bottom: 25px;
  }

  @media (min-width: 1600px) {
    flex-direction: row;
    justify-content: space-around;
    margin: 0 -10px;
    .table-sm-container {
      flex-basis: 50%;
      margin: 0 10px;
    }
  }
}
</style>